<template>
  <div>
    <div class="facilityBox">
      <!-- form表单 -->
      <el-form class="manageForm" :model="manageForm" :inline="true" label-width="70px">
        <el-form-item label="企业名称" prop="EnterpriseFullName" v-if="pageIndex != 3 && pageIndex != 4">
          <el-input class="fromInp" v-model="manageForm.EnterpriseFullName" placeholder="输入企业名称"
            @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item label="司机姓名" prop="DriverName" v-if="pageIndex != 4">
          <el-input class="fromInp" v-model="manageForm.DriverName" placeholder="输入司机姓名"
            @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="LegalPersonlPhone" v-if="pageIndex != 4">
          <el-input class="fromInp" v-model="manageForm.LegalPersonlPhone" placeholder="输入手机号"
            @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item v-if="pageIndex == 4" label="车牌号" prop="CarNumber">
          <el-input class="fromInp" v-model="manageForm.CarNumber" placeholder="输入车牌号"
            @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item v-if="pageIndex != 0 && pageIndex != 4" label="身份证号" prop="IDCard">
          <el-input class="fromInp" v-model="manageForm.IDCard" placeholder="输入身份证号"
            @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item v-if="pageIndex == 3 || pageIndex == 4" label="上报状态">
          <el-select v-model="manageForm.ReportStatus" placeholder="请选择上报状态" @change="searchChange()">
            <el-option v-for="item in trafficType" :key="item.code" :label="item.name" :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择时间" prop="TaskDatetime">
          <el-date-picker v-model="TaskDatetime" type="daterange" range-separator="至" start-placeholder="开始日期"
            value-format="yyyy-MM-dd" end-placeholder="结束日期" @change="searchChange()">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
          <!-- <el-button type="primary" size="medium" @click="exportData(pageIndex)" icon="el-icon-download">
            {{
              pageIndex == 0?'导出服务商表格':
              pageIndex == 1?'导出货主表格':
              pageIndex == 2?'导出代理商表格':
              pageIndex == 3?'导出司机表格':'导出车辆表格'
            }}
          </el-button> -->
          <el-button v-if="pageIndex == 3 || pageIndex == 4" type="primary" size="medium"
            :disabled="traffic.length == 0 ? true : false" @click="upTraffic()" icon="el-icon-upload2">
            {{ pageIndex == 3 ? '批量上报司机' : '批量上报车辆' }}
          </el-button>
        </el-form-item>
      </el-form>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="所有服务商" name="first">
          <allService :tableData="tableData" :loading="loading" :pageIndex="pagination.page">
          </allService>
        </el-tab-pane>
        <el-tab-pane label="所有货主" name="second">
          <allOwner :tableData="tableData" :loading="loading" :pageIndex="pagination.page">
          </allOwner>
        </el-tab-pane>
        <el-tab-pane label="所有代理商" name="agency">
          <allAgency :tableData="tableData" :loading="loading" :pageIndex="pagination.page">
          </allAgency>
        </el-tab-pane>
        <el-tab-pane label="所有司机" name="third">
          <allDriver :tableData="tableData" :loading="loading" :pageIndex="pagination.page" @refreshList="GetPlatFormBy"
            @tableSelectionChange="tableSelectionChange" @showLoading="showLoading" @GetPlatFormBy="GetPlatFormBy">
          </allDriver>
        </el-tab-pane>
        <el-tab-pane label="所有车辆" name="fourth">
          <allCar :tableData="tableData" :loading="loading" :pageIndex="pagination.page"
            @tableSelectionChange="tableSelectionChange" @showLoading="showLoading" @VehicleList="VehicleList">
          </allCar>
        </el-tab-pane>
      </el-tabs>
      <div style="margin-top: 10px">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import allService from "@/components/businessCmpt/allService";
import allOwner from "@/components/businessCmpt/allOwner";
import allDriver from "@/components/businessCmpt/allDriver";
import allCar from "@/components/businessCmpt/allCar";
import allAgency from "@/components/businessCmpt/allAgency";
import {
  CustomerAllList,
  SupplierAllList,
  allDriverList,
  allVehicle,
  getAllAgency
} from "@/api/auditInfo/certified/index";
import { upExport } from "@/api/finance/account/index";
import { upTrafficDriver, upTrafficVehicle } from "@/api/common/common"
export default {
  data() {
    return {
      // 分页参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // Tab选中栏
      activeName: "first",
      // 筛选条件数据
      manageForm: {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        IDCard: "",
        CarNumber: "",
        ReportStatus: "",
        DriverName: ''
      },
      // 选中的实际
      TaskDatetime: ["", ""],
      pageIndex: 0,
      // 数据列列表
      tableData: [],
      // 表格loading
      loading: true,
      // 上报列表
      traffic: [],
      // 上报状态
      trafficType: [
        {
          name: '全部',
          code: ''
        }, {
          name: '未上报',
          code: '0'
        }, {
          name: '上报成功',
          code: '1'
        }, {
          name: '上报失败',
          code: '2'
        }
      ]
    };
  },
  created() {
    // if (this.$route.query.pageIndex) {
    //   this.pagination.page = Number(this.$route.query.pageIndex)
    // } else {
    //   // 查询所有服务商
    //   this.SuppAllList()
    // }
    if (this.$route.query.urlType) {
      this.activeName = this.$route.query.urlType
      if (this.activeName == 'first') {
        this.SuppAllList();
      } else if (this.activeName == 'second') {
        this.CustomerAllList();
      } else if (this.activeName == 'agency') {
        this.getAllAgency()
      } else if (this.activeName == 'third') {
        this.GetPlatFormBy();
      } else {
        this.VehicleList();
      }
    } else {
      this.SuppAllList();
    }
  },
  mounted() {
    this.chooseTabsIndex()
    // 监听浏览器刷新
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  destroyed() {
    // 移除浏览器刷新
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  methods: {
    searchChange() {
      this.search()
    },
    showLoading() {
      this.loading = !this.loading
    },
    // 浏览器刷新重置参数
    beforeunloadHandler(e) {
      if (this.$route.path == '/platform/alluser/index') {
        // 重置路由
        this.$router.push({
          path: "/platform/alluser/index"
        });
      }
    },
    // 上报
    upTraffic() {
      let data = []
      this.traffic.forEach(item => {
        let params = {
          id: item.DriverIDCard ? item.DriverIDCard : item.VehicleCarNumber
        }
        data.push(params)
      })
      this.$confirm('是否上报已选中的数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.activeName == "third") {
          upTrafficDriver(data).then(res => {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
          }).finally(() => {
            this.traffic = []
            // 查询所有司机
            this.GetPlatFormBy()
          })
        }
        if (this.activeName == "fourth") {
          upTrafficVehicle(data).then(res => {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
          }).finally(() => {
            this.traffic = []
            // 查询所有车辆
            this.VehicleList()
          })
        }
      })
    },
    // 获取需要上报的列表
    tableSelectionChange(e) {
      this.traffic = e
    },
    //刷新页面时确定tabs的选中
    chooseTabsIndex() {
      if (this.$route.fullPath.indexOf("first") != -1) {
        this.activeName = "first"
        this.pageIndex = 0
        // 查询所有服务商
        this.SuppAllList();
      } else if (this.$route.fullPath.indexOf("second") != -1) {
        this.activeName = "second"
        this.pageIndex = 1
        // 查询所有货主
        this.CustomerAllList();
      } else if (this.$route.fullPath.indexOf("agency") != -1) {
        this.activeName = "agency"
        this.pageIndex = 2
        // 查询所有代理商
        this.getAllAgency()
      } else if (this.$route.fullPath.indexOf("third") != -1) {
        this.activeName = "third"
        this.pageIndex = 3
        // 查询所有司机
        this.GetPlatFormBy();
      } else if (this.$route.fullPath.indexOf("fourth") != -1) {
        this.activeName = "fourth"
        this.pageIndex = 4
        // 查询所有车辆
        this.VehicleList();
      }
    },
    // 导出表格
    exportData(index) {
      this.loading = true
      let params = {
        fileType: '',
        fileName: '',
        Name: '',
        TimeStart: '',
        TimeEnd: '',
        CarNumber: '',
        IDCard: '',
        Phone: ''
      }
      if (index == 0) {
        params.fileType = '平台服务商导出'
        params.fileName = '服务商资料'
        params.Name = this.manageForm.EnterpriseFullName
        params.Phone = this.manageForm.LegalPersonlPhone
        params.TimeStart = this.TaskDatetime[0]
        params.TimeEnd = this.TaskDatetime[1]
      } else if (index == 1) {
        params.fileType = '平台货主导出'
        params.fileName = '货主资料'
        params.Name = this.manageForm.EnterpriseFullName
        params.Phone = this.manageForm.LegalPersonlPhone
        params.TimeStart = this.TaskDatetime[0]
        params.TimeEnd = this.TaskDatetime[1]
        params.IDCard = this.manageForm.IDCard
      } else if (index == 2) {
        params.fileType = '平台代理商导出'
        params.fileName = '代理商资料'
        params.Name = this.manageForm.EnterpriseFullName
        params.Phone = this.manageForm.LegalPersonlPhone
        params.TimeStart = this.TaskDatetime[0]
        params.TimeEnd = this.TaskDatetime[1]
        params.IDCard = this.manageForm.IDCard
      } else if (index == 3) {
        params.fileType = '平台司机导出'
        params.fileName = '司机资料'
        params.Name = this.manageForm.EnterpriseFullName
        params.Phone = this.manageForm.LegalPersonlPhone
        params.DriverName = this.manageForm.DriverName
        params.TimeStart = this.TaskDatetime[0]
        params.TimeEnd = this.TaskDatetime[1]
        params.IDCard = this.manageForm.IDCard
      } else {
        params.fileType = '平台车辆导出'
        params.fileName = '车辆资料'
        params.Name = this.manageForm.EnterpriseFullName
        params.Phone = this.manageForm.LegalPersonlPhone
        params.TimeStart = this.TaskDatetime[0]
        params.TimeEnd = this.TaskDatetime[1]
        params.CarNumber = this.manageForm.CarNumber
      }
      upExport({ Json: JSON.stringify(params) }).then(res => {
        window.location.href = res.pathList[0].pathName
      }).finally(() => {
        this.loading = false
      })
    },
    // 清空搜索
    resetForm() {
      this.manageForm = {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        IDCard: "",
        CarNumber: "",
        ReportStatus: '',
        DriverName: ''
      };
      this.TaskDatetime = ["", ""];
      this.pagination.page = 1;
      if (this.pageIndex == 0) {
        this.SuppAllList();
      } else if (this.pageIndex == 1) {
        this.CustomerAllList();
      } else if (this.pageIndex == 2) {
        this.getAllAgency()
      } else if (this.pageIndex == 3) {
        this.GetPlatFormBy();
      } else {
        this.VehicleList();
      }
    },
    // 搜索
    search() {
      this.pagination.page = 1;
      if (this.pageIndex == 0) {
        this.SuppAllList();
      } else if (this.pageIndex == 1) {
        this.CustomerAllList();
      } else if (this.pageIndex == 2) {
        this.getAllAgency()
      } else if (this.pageIndex == 3) {
        this.GetPlatFormBy();
      } else {
        this.VehicleList();
      }
    },
    // 查询平台所有代理商
    getAllAgency() {
      this.loading = true
      let data = this.manageForm;
      data.SubmitDatetimeStart = this.TaskDatetime[0];
      data.SubmitDatetimeEnd = this.TaskDatetime[1];
      data.pageIndex = this.pagination.page;
      data.pageSize = this.pagination.pagesize;
      getAllAgency({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
    // 查询所有服务商
    SuppAllList() {
      this.loading = true
      let data = this.manageForm;
      data.SubmitDatetimeStart = this.TaskDatetime[0];
      data.SubmitDatetimeEnd = this.TaskDatetime[1];
      data.pageIndex = this.pagination.page;
      data.pageSize = this.pagination.pagesize;
      SupplierAllList({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
    // 查询所有货主
    CustomerAllList() {
      this.loading = true
      let data = this.manageForm;
      data.SubmitDatetimeStart = this.TaskDatetime[0];
      data.SubmitDatetimeEnd = this.TaskDatetime[1];
      data.pageIndex = this.pagination.page;
      data.pageSize = this.pagination.pagesize;
      CustomerAllList({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
    // 查询司机列表
    GetPlatFormBy() {
      this.loading = true
      let data = this.manageForm;
      data.SubmitDatetimeStart = this.TaskDatetime[0];
      data.SubmitDatetimeEnd = this.TaskDatetime[1];
      data.pageIndex = this.pagination.page;
      data.pageSize = this.pagination.pagesize;
      data.Status = 2;
      data.type = 0;
      allDriverList({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
    // 查询车辆列表
    VehicleList() {
      this.loading = true
      let data = this.manageForm;
      data.SubmitDatetimeStart = this.TaskDatetime[0];
      data.SubmitDatetimeEnd = this.TaskDatetime[1];
      data.pageIndex = this.pagination.page;
      data.pageSize = this.pagination.pagesize;
      data.Status = 2;
      data.type = 0;
      allVehicle({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e
      if (this.pageIndex == 0) {
        this.SuppAllList();
      } else if (this.pageIndex == 1) {
        this.CustomerAllList();
      } else if (this.pageIndex == 2) {
        this.getAllAgency()
      } else if (this.pageIndex == 3) {
        this.GetPlatFormBy();
      } else {
        this.VehicleList();
      }
    },
    // tab切换
    handleClick(tab) {
      this.pagination.page = 1
      this.pageIndex = Number(tab.index)
      if (tab.index == 0) {
        this.SuppAllList();
      } else if (tab.index == 1) {
        this.CustomerAllList();
      } else if (tab.index == 2) {
        this.getAllAgency()
      } else if (tab.index == 3) {
        this.GetPlatFormBy();
      } else {
        this.VehicleList();
      }
    },
  },
  components: {
    allService,
    allOwner,
    allDriver,
    allCar,
    allAgency
  },
};
</script>

<style lang="scss" scoped></style>