<template>
    <div>
        <el-table
            :data="relationList"
            :header-cell-style="{ background: '#f0f0f0', color: '#666' }">
            <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
            <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" width="200" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="180" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" width="270" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="IdentityType" label="角色属性" width="100" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="SignDatetime" label="添加时间" width="160" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="" label="最终提交来源" width="100" show-overflow-tooltip></el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <!-- 分页 -->
          <el-pagination background
            class="pagination"
            @current-change="handleCurrentChange"
            :current-page.sync="pagination.page"
            :page-size="pagination.pagesize"
            layout="total, prev, pager, next, jumper"
            :total="relationTotal" >
          </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    props:['relationList','relationTotal'],
    data() {
        return {
            //分页控件相关参数
            pagination: {
                page: 1, //当前所处的页码
                pagesize: 10, //每次请求的数量
            },
        }
    },
    methods:{
        handleCurrentChange(e){
            this.$emit("relationChange",e)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>