import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 获取货主列表
export const getCustomerBy = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/PlatForm/GetCustomerByTaskSceneList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取货主费率
export const getPlatFormBy = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/PlatForm/GetPlatFormByTaskSceneTypeList`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取货主设置费率
export const getTaskSceneList = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/PlatForm/GetPlatFormByCustomerTaskSceneList?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })

}

// 设置货主费率
export const UpdateSSCInvoiceType = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/PlatForm/UpdateSSCInvoiceType`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 设置推荐人ID
export const UpdateAgentID = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/PlatForm/UpdateAgentID`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取货主设置费率
export const GetThirdStatus = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/PlatForm/GetThirdStatus?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取历史费率
export const GetRateChangeRecord = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/RateChangeRecord/GetRateChangeRecord?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取历史费率详情
export const GetRateChangeRecordDetail = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/RateChangeRecord/GetRateChangeRecordDetail?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}