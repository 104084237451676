<template>
  <div>
    <el-table 
        :data="sysConfigList" 
        :header-cell-style="{background:'#f0f0f0',color:'#666'}" >
        <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
        <el-table-column align="center" prop="KeyText" label="文件名"></el-table-column>
        <el-table-column align="center" label="认证状态">
            <template slot-scope="scope">
                <el-tag v-if="scope.row.Status == '已认证'" type="success">{{ scope.row.Status }}</el-tag>
                <el-tag v-else type="warning">{{ scope.row.Status }}</el-tag>
            </template>
        </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
    props:['sysConfigList'],
    data(){
        return{

        }
    }
}
</script>

<style lang="scss" scoped>

</style>